import React from 'react';
import SEO from '../components/seo';

const Terms = ({ location }) => {
    return (
        <article className="page-content page-content">
            <SEO title="Terms of Use - Mike" url={location.pathname} />
            <header className="text-center">
                <h1>Terms of Use</h1>
                <p className="page-content__date">Last updated: 21st May 2018</p>
            </header>
            
            <div className="page-content__body">            
                <div className="page-content__inner">
                    <p>
                        These Terms and Conditions (hereinafter, referred to as "the Terms") for the website <a href="https://www.mikeapp.co">https://www.mikeapp.co</a> (hereinafter, referred to as the "Website") and the mobile app - Mike (hereinafter, referred to as "the App") constitute a legal agreement between the user ("you", "your", "user") of the Website and the App (collectively referred to as "the Service") and LoudUp LTD having a principal place of business at Manor House, Mill Lane, Southampton, Hampshire, United Kingdom, SO16 0YE (hereinafter, referred to as the "Company", "we", "us", or "our"). By using the Service, you acknowledge that you have read the Terms and agree to be bound by them. If you do not agree to the Terms, please do not use the Service. The Company grants you the right to use the Service only in the case that you agree to the Terms.
                    </p>
                    <ol>
                        <li>The Service
                            <ol>
                                <li>The Service is a platform allowing you to manage your music career.</li>
                                <li>The Service allows the users to register an account by using their Facebook or Twitter account, or creating an account directly with the Service.</li>
                            </ol>
                        </li>
                        <li>Company's Content
                            <ol>
                                <li>The Service contains materials that are owned by the Company (hereinafter, referred to as "Company’s Content"). Such materials include, but are not limited to, text, images, and source code. Company’s Content is intellectual property of the Company. Company’s Content is protected by the intellectual property law of the United Kingdom and the applicable international intellectual property laws.</li>
                            </ol>
                        </li>
                        <li>User-generated content
                            <ol>
                                <li>The Service offers users the opportunity to: (1) create tasks, set goals, manage events and finances; (2) register an email address, display name, and band name; (hereinafter, the information mentioned in points (1) and (2) of this Section 3.1 is collectively referred to as "User-Generated Content").</li>
                                <li>
                                    You agree not to submit any User-Generated Content or other material that:
                                    <ul>
                                        <li>violates the applicable privacy laws;</li>
                                        <li>contains malware, including, but not limited to, Trojan horses, viruses, worms or other software that can alter, interrupt, impede, limit or destroy the performance and/or functioning of any software, hardware, or other equipment;</li>
                                        <li>is an advertisement or promotion for any product or service that had not been approved in writing by the Company;</li>
                                        <li>constitutes an unfair or deceptive trade practice;</li>
                                        <li>is false or misleading;</li>
                                        <li>infringes the intellectual property rights of others;</li>
                                        <li>is abusive, defamatory, harassing, hateful, libelous, obscene, profane, sexually explicit, threatening, and vulgar;</li>
                                        <li>is racially, ethnically or otherwise objectionable in any manner;</li>
                                        <li>promotes the use of alcohol and tobacco;</li>
                                        <li>promotes the use of illegal drugs or any other illegal substance;</li>
                                        <li>is submitted by using fake email addresses;</li>
                                        <li>constitutes spam or other abusive messaging;</li>
                                    </ul>
                                </li>
                                <li>You further agree that any User-Generated Content submitted by you does not contain any confidential, proprietary or trade secret information. The Company shall have no obligation to store, keep copies of or return any User-Generated Content. The Company further reserves the right, in its sole discretion, to modify, delete or remove any User-Generated Content from the Service that it deems to be in violation of the foregoing requirements.</li>
                                <li>You will be solely responsible and liable for, and will indemnify the Company and its officers, directors, employees and agents from and against any losses, expenses, liabilities, claims, costs, and damages arising from your User-Generated Content.</li>
                                <li>
                                    By entering User-Generated Content into the Service, you agree that such User-Generated Content will be made available to other users of your Artist or Band profile. By entering User-Generated Content into the Service, you grant to the Company unrestricted, royalty-free, perpetual, and irrevocable rights to:
                                    <ul>
                                        <li>to archive, cache, crawl, host, index, modify, and store your User-Generated Content to appropriate media formats, standards or mediums as part of the services provided by the Company;</li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li>Support queries
                            <ol>
                                <li>By using the Website, you agree that any support queries submitted by you through the Website will be handled by us through the website <a href="http://helpscout.net" target="_blank" rel="noreferrer noopener"> http://helpscout.net</a> (hereinafter, referred to as "HelpScout").</li>
                                <li>By using the App, you agree that any crash reports generated by the App will be handled by us through the website <a href="https://appcenter.ms" target="_blank" rel="noreferrer noopener">https://appcenter.ms</a> (hereinafter, referred to as "App Center").</li>
                            </ol>
                        </li>    
                            <li>Google Places API
                            <ol>
                                <li>The Service is developed with the Google Places API, Google Place Autocomplete, and Google Maps. By using the Service, you agree to be bound by Google's Terms of Service available on <a href="http://www.google.com/intl/en/policies/terms/" target="_blank" rel="noreferrer noopener">http://www.google.com/intl/en/policies/terms/</a>.</li>
                            </ol>
                        </li>                        
                        <li>Newsletter
                            <ol>
                                <li>If you would like to subscribe to our mailing list, you need to either create an account in the Service or use the "Subscribe to the Mike Mailing List" functionality available on the Website.</li>
                                <li>If you would like to unsubscribe from our mailing list, please use the unsubscribe link included in any newsletter submitted by the Company to you.</li>
                                <li>By signing up for our newsletter, you agree that the newsletter will be delivered by MailChimp, which is a trading name of Rocket Science Group, LLC. Rocket Science Group, LLC is a limited liability company based in the United States.</li>
                            </ol>
                        </li>     
                        <li>Charges
                            <ol>
                                <li>Information about the fees charged for the use of the service are displayed in the "Pricing" section of the Service. You are responsible for any taxes and duties arising from your use of the Service. We reserve the right to make changes to the fees charged by informing you in writing with 30 days notice.</li>
                            </ol>
                        </li>
                        <li>Payments
                            <ol>
                                <li>A subscription is charged in order to use parts of the service. In order to take out a subscription to the service, you must be 18 years or over, or have consent from a parent or guardian.</li>
                                <li>Your subscription will start with a 1 month trial period, after which you will be charged on a monthly or annual basis, until you cancel your subscription or we terminate your subscription as specified in Section 18.</li>
                                <li>Payments for subscriptions are made by third party providers, which include the Apple AppStore, Google Play Store, and Stripe. By subscribing to the service, you agree to the terms and conditions specified by these providers.</li>
                            </ol>
                        </li>
                        <li>A license to use the Service
                            <ol>
                                <li>Subject to the provisions in the Terms, the Company grants you a personal, nonexclusive, nontransferable, non-sublicenseable, limited license to use the Service.</li>
                            </ol>
                        </li>
                        <li>License restrictions
                            <ol>
                                <li>Unless explicitly allowed in the Terms, you may not: (i) copy the Company's Content; (ii) distribute the Company's Content; (iii) adapt, translate, reverse engineer, make alterations, decompile, disassemble or make derivative works based on the Company's Content; (iv) use, rent, loan, sub-license, lease, distribute or attempt to grant other rights to the Company's Content to third parties.</li>
                            </ol>
                        </li>                  
                        <li>Ownership
                            <ol>
                                <li>All Company's Content featured or displayed in or through the Service and all trademarks, service marks and trade names of the Company included therein, are intellectual property of the Company, its licensors, vendors, agents and/or other content providers.</li>
                                <li>All User-Generated Content is owned by their respective owners and the Company is not responsible in any manner for that content.</li>
                            </ol>
                        </li>    
                        <li>Your warranty to the Company
                            <ol>
                                <li>You represent and warrant that: (i) you have the authority to bind yourself to these Terms; (ii) you will use the Service only for purposes that are permitted by these Terms; (iii) your use of the Service will comply with all applicable laws; (iv) you are at least 13 years of age;</li>
                            </ol>
                        </li>
                        <li>Privacy
                            <ol>
                                <li>By using the Service, you agree that the Company may collect and process your personal information. The Privacy Policy published on <a href="https://www.mikeapp.co/Privacy">https://www.mikeapp.co/Privacy</a> governs the collection, processing, and disclosure of your personal information in relation to the Service</li>
                            </ol>
                        </li>   
                        <li>Availability
                            <ol>
                                <li>The Company will use reasonable efforts to make the Service available at all times. However, you acknowledge that the Service is provided over the Internet and so the quality and availability of the Service may be affected by factors outside our reasonable control.</li>
                                <li>The Company does not accept any responsibility for unavailability of the Service due to bandwidth problems, equipment failure, or acts of God.</li>
                            </ol>
                        </li>
                        <li>Links
                            <ol>
                                <li>The Service may contain links to other websites. The Company is not responsible for the content of those websites.</li>
                            </ol>
                        </li>
                        <li>
                            <ol>Disclaimer of Warranties
                                <li>THE COMPANY IS LICENSING THE SERVICE "AS IS," "AS AVAILABLE," AND "WITH ALL FAULTS." THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY, RELIABILITY, TIMELINESS, AND ACCURACY, FOR ANY PURPOSE, OF THE SERVICE. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, REGARDING THE SERVICE AND ITS OPERATION. THE COMPANY EXPRESSLY DISCLAIMS THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</li>
                            </ol>
                        </li>
                        <li>Limitation of Liability
                            <ol>
                                <li>TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR OTHER RELATED OR SIMILAR DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, AND THE LIKE CONNECTED WITH THE USE OF OR INABILITY TO USE THE SERVICE, AND FOR ANY CAUSE OF ACTION, INCLUDING CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                            </ol>
                        </li>
                        <li>Termination
                            <ol>
                                <li>These Terms are effective until terminated.</li>
                                <li>The Company may terminate the Terms at any time at its sole discretion by sending you an email or a message in the Website or App. Additionally, your rights under these Terms will terminate automatically if you fail to comply with any of the provisions contained in the Terms.</li>
                                <li>Upon termination, all legal rights and licenses granted to you hereunder shall terminate immediately and you shall cease all use of the Service.</li>
                            </ol>
                        </li>
                        <li>Governing Law
                            <ol>
                                <li>The Terms shall be governed by the laws of the United Kingdom. You irrevocably consent to the exclusive jurisdiction and venue of the courts in London, the United Kingdom for all disputes arising out of or relating to these Terms.</li>
                            </ol>
                        </li>
                        <li>Amendment of these Terms
                            <ol>
                                <li>We may change these Terms as we may deem necessary from time to time, or as may be required by law. Any changes will be immediately posted on the Service and you will be deemed to have accepted the changes on your first use of the Service following the alterations. We recommend that you check this page regularly to keep up-to-date.</li>
                            </ol>
                        </li>
                    </ol>                
                </div>
            </div>
        </article>
    );
};

export default Terms;